import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ExpenseReimbursementUploadReceipt.css';
import { userActions } from '../../_store';
import { creditcardActions } from '../../_store';
import { clientsActions } from '../../_store';
import { payeeActions } from '../../_store';
import { projectsActions } from '../../_store';
import { tasksActions } from '../../_store';
import { subtasksActions } from '../../_store';
import { purchaseTypeActions } from '../../_store';
import Select from 'react-select';
import { Navigate } from 'react-router-dom';
import ImageUploading from 'react-images-uploading';
import { history } from '../../_helpers';
import CurrencyInput from 'react-currency-input-field';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const billTo = [
  { value: 'C', label: 'Client', key: 1 },
  { value: 'T', label: 'Tek', key: 2 }
]

export { ExpenseReimbursementUploadReceipt };

function ExpenseReimbursementUploadReceipt() {
  const [isClearable, setIsClearable] = useState(true);
  const [billOption, selectBillTo] = useState();
  const [clientOption, setClient] = useState();
  const [creditOption, setCreditOption] = useState("false");
  const [clientExpTaskLevel, setClientExpTaskLevel] = useState();
  const [subTaskId, setSubTaskId] = useState();
  const [projectOption, setProject] = useState();
  const [submitCheck, setSubmit] = useState(false);
  let [loading, setLoading] = useState(false);
  const [taskOption, setTask] = useState();
  const [saveError, setError] = useState('');
  const [images, setImages] = React.useState([]);
  const { user: authUser } = useSelector(x => x.auth);
  const [response, setResponse] = useState(false);
 

  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
  }
  const userDefaultValue = {
    user_active: authUser.user.user_active,
    user_fname: authUser.user.user_fname,
    user_id: authUser.user.user_id,
    user_lname: authUser.user.user_lname,
    user_mname: authUser.user.user_mname
  }

  const { payee } = useSelector(x => x.payee);
  const [userValue, setUserValue] = useState(userDefaultValue.user_id);
  const current = new Date();
  const Todaysdate = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}-${current.getDate().toString().padStart(2, '0')}`;
  // const Todaysdate = `${current.getFullYear()}-${(current.getMonth() > 9 ? (current.getMonth() + 1) : '0' + (current.getMonth() + 1))}-${current.getDate() > 9 ? current.getDate() : '0' + current.getDate()}`;
  const [currentDate, setDate] = useState(Todaysdate);
  const [ticketNumber, setTicketNumber] = useState('');
  const dispatch = useDispatch();
  const { users } = useSelector(x => x.users);
  const { clients } = useSelector(x => x.clients);
  let { projects } = useSelector(x => x.projects);
  let { tasks } = useSelector(x => x.tasks);
  let { subtasks } = useSelector(x => x.subtasks);
  let { purchaseType } = useSelector(x => x.purchaseType);

  const targetDiv = document.getElementById("image-upload")?.getElementsByTagName("input")[0];
  targetDiv?.setAttribute('required', 'required');
  if (images.length > 0) {
    targetDiv?.removeAttribute('required');
  }

  useEffect(() => {
    const targetDiv = document.getElementById("image-upload")?.getElementsByTagName("input")[0];
    targetDiv?.setAttribute('required', 'required');
    dispatch(userActions.getAll());
    dispatch(creditcardActions.getAll());
    dispatch(clientsActions.getAll());
    dispatch(payeeActions.getAll());
    dispatch(purchaseTypeActions.getAll());

  }, []);
  const maxNumber = 5;

  //image upload
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };


  //Bill dropdown
  const selectBill = (e) => {
    if (e) {
      selectBillTo(e.key);
      onChangeGetValue({ target: { value: e?.value, name: 'expense_trans_bill_to' } })
      if (e.key == 1) {
        inputField.expense_trans_purchase_type_id = '';
      }
      else if (e.key == 2) {
        inputField.expense_trans_bill_to_client_id = '';
        inputField.expense_trans_bill_to_project_id = '';
        inputField.expense_trans_bill_to_ts_task_id = '';
      }

    } else {
      selectBillTo(undefined)
    }
  };
  const selectClient = (e) => {
    if (e) {

      setClientExpTaskLevel(e.client_exp_at_task_level);
      const param = e.client_id;
      dispatch(projectsActions.getAllById({ param }));
      setClient(e.client_id);
      if (billOption == 1) {
        onChangeGetValue({ target: { value: e?.client_id, name: 'expense_trans_bill_to_client_id' } })
      }
      if (e.client_exp_at_task_level == null || e.client_exp_at_task_level == 0) {
        inputField.expense_trans_bill_to_project_id = '';
        inputField.expense_trans_bill_to_ts_task_id = '';
      }
    } else {
      projects = [];
      setProject(undefined)
      tasks = [];
      subtasks = [];
      setClient(undefined);
      setTask(undefined);
      inputField.expense_trans_bill_to_project_id = '';
      inputField.expense_trans_bill_to_ts_task_id = '';
    }
  };

  const selectProject = (e) => {
    if (e) {
      const param = e.project_id;
      dispatch(tasksActions.getAllById({ param }));
      if (billOption == 1) {
        setProject(e.project_id)
        onChangeGetValue({ target: { value: e?.project_id, name: 'expense_trans_bill_to_project_id' } })
      }
    } else {
      tasks = [];
      subtasks = [];
      setProject(undefined);
      setTask(undefined)
    }
  };
  const selectTasks = (e) => {
    if (e) {
      const param = e.ts_task_id;
      setTask(e.ts_task_id)
      dispatch(subtasksActions.getAllById({ param }));

    } else {
      subtasks = [];
      setTask(undefined)
    }
  };
  const selectSubTasks = (e) => {
    if (e) {
      setSubTaskId(e.ts_task_id);
    }
  };
  const selectPurchaseType = (e) => {
    if (e) {
      if (billOption == 2) {
        onChangeGetValue({ target: { value: e?.cc_trans_purchase_type_id, name: 'expense_trans_purchase_type_id' } })
      }
    }
  };


  //filled values
  const onChangeGetValue = e => {
    const { name, value } = e.target;
    setInputField(prevState => ({
      ...prevState,
      [name]: value,
    }));
    if (name == 'expense_trans_dt') {
      setDate(value);
    }
    if (name == 'used_by') {
      setUserValue(value);
    }
    if (name == 'expense_trans_amt') {
      inputField.expense_trans_bill_amt = value
    }
  };


  const onChangeticketNumber = (e) => {
    const value = e.target.value?.replace(/\D/g, "");
    setTicketNumber(value);
    inputField.expense_trans_ticket_number = value;
  };


  const [inputField, setInputField] = useState({
    expense_trans_dt: currentDate,
    expense_trans_vendor_id: '',
    expense_trans_amt: null,
    expense_trans_memo: '',
    expense_trans_status: 'S',
    expense_trans_user_id: userValue,
    expense_qb_account_id: null,
    expense_trans_qb_status: 'P',
    expense_trans_bill_to: '',
    expense_trans_bill_amt: null,
    expense_trans_bill_to_client_id: '',
    expense_trans_bill_status: 'P',
    expense_trans_ticket_number: '',
    expense_trans_reviewed: 0,
    expense_trans_bill_to_ts_task_id: '',
    credit: "false",
    expense_trans_purchase_type_id: '',
    expense_trans_uploaded_by: userDefaultValue.user_id,
    expense_trans_bill_to_project_id: '',
  });

  const radioOptionChange = (e) => {
    const value = e.target.value;
    inputField.credit = value;
    setCreditOption(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmit(true)
    
    if (clientExpTaskLevel == 0 || clientExpTaskLevel == null) {
      inputField.expense_trans_bill_to_ts_task_id = null;
    } else if (clientExpTaskLevel == 1) {
      if (subTaskId) {
        inputField.expense_trans_bill_to_ts_task_id = subTaskId;
      } else if (taskOption) {
        inputField.expense_trans_bill_to_ts_task_id = taskOption;
      }
    }
    if (inputField.expense_trans_amt != 0) {
      setLoading(true);
      apiCall();
    }
  }
  // function apiCall() {
  //   document.body.style.opacity = 0.5;
  //   const baseUrl = `${process.env.REACT_APP_API_URL}/Reimbursement/`;
  //   fetch(baseUrl + 'SaveExpenseReimbursement', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + authUser.jwtToken
  //     },
  //     body: JSON.stringify(inputField)
  //   }).then((response) => {

  //     setLoading(true);
  //     if (response.ok) {
  //       var formData = new FormData();
  //       const fileData = images[0].file;
  //       formData.append("files", fileData);
  //       formData.append("expense_trans_id", 0);

  //       fetch(baseUrl + 'UploadExpenseReimbursementFile', {
  //         method: 'POST',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Authorization': 'Bearer ' + authUser.jwtToken
  //         },
  //         body: formData
  //       }).then((response) => {
  //         setLoading(true);
  //         if (response.ok) {
  //           window.scrollTo(0, 0);
  //           setResponse(true);
  //           setTimeout(() => {
  //             setResponse(false);
  //             window.location.reload();
  //             document.body.style.opacity = 1;
  //           }, 2000);
  //           setError('');
  //           setSubmit(false);
  //           setLoading(false);

  //         } else {
  //           setError(response.statusText);
  //           setLoading(false);
  //         }

  //       });
  //     } else {
  //       setError(response.statusText);
  //       setLoading(false);

  //     }
  //   });
  // }

  function apiCall() {
    document.body.style.opacity = 0.5;
    const baseUrl = `${process.env.REACT_APP_API_URL}/Reimbursement/`;
  
   
    const formData = new FormData();
  
    // Append all fields from inputField to FormData
    for (const key in inputField) {
      if (Object.prototype.hasOwnProperty.call(inputField, key)) {
        formData.append(key, inputField[key]);
      }
    }
  
    // Append the file data
    const fileData = images[0].file;
    formData.append("files", fileData);
  
  
    formData.append("expense_trans_id", 0);
  
    // Make a single API call
    fetch(baseUrl + 'UploadExpenseReimbursementFile', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + authUser.jwtToken,
        'Accept': 'application/json',
      },
      body: formData,
    }).then((response) => {
      setLoading(true);
      if (response.ok) {
        window.scrollTo(0, 0);
        setResponse(true);
        setTimeout(() => {
          setResponse(false);
          window.location.reload();
          document.body.style.opacity = 1;
        }, 2000);
        setError('');
        setSubmit(false);
        setLoading(false);
  
      } else {
        setError(response.statusText);
        setLoading(false);
      }
    });
  }

  return (
    <>
      <div className="App">
        <div className="expense-receipts">
          <div className='dash-icon'>
            <div></div>
            <h6 className='dash-title'>EMPLOYEE EXPENSE REIMBURSEMENT</h6>
            <OverlayTrigger
              key='bottom'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-bottom'>
                  Expense Reimbursement Dashboard
                </Tooltip>
              }
            >
              <a href="/ExpenseReimbursementDashboard" className='dashboard-icon-upload'><img src={'/assets/images/dashboard2.png'} /></a>
            </OverlayTrigger>

          </div>
          {loading &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>}
          {response &&
            <div className='flex toast-msg'>
              <div className="alert alert-success" role="alert">
                Receipt uploaded successfully
              </div>
            </div>}
          

          <main>
            <div className="container">
              <div className="">
                <div className="receipts-form">
                  <form onSubmit={handleSubmit}>

                    {/* {radio} */}
                    <div className="field-group">
                      <div className="field-element"></div>
                      <div className="field-element flex">
                        <div className="field">
                          <input type="radio" id="charge" value="false" checked={creditOption == "false"} name="credit_option" onChange={radioOptionChange} />
                          <label htmlFor="charge">CHARGE </label> </div>
                        <div className="field credit">
                          <input type="radio" id="credit" name="credit_option" value="true" checked={creditOption === "true"} onChange={radioOptionChange} />
                          <label htmlFor="credit">CREDIT</label></div>

                      </div>
                      <div className="field-element"></div>
                    </div>
                    {/* {radio block} */}
                    <div className="field-group">
                      
                      <div className="field-element">
                        <div className="field">
                          <div className="label">EMPLOYEE NAME<span className="mandatory-field">* </span></div>

                          <Select options={users}
                            isClearable={isClearable}
                            getOptionLabel={e => e.user_id}
                            getOptionValue={e => e.user_id}
                            defaultValue={userDefaultValue}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix" name="expense_trans_user_id"
                            required
                            onChange={data => onChangeGetValue({ target: { value: data?.user_id, name: 'expense_trans_user_id' } })}

                          />

                        </div>
                      </div>
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="expense_trans_dt" className="label">Transaction Date<span className="mandatory-field">* </span></label>
                          <input name="expense_trans_dt" id="date" type="date" min="2022-12-27" onChange={onChangeGetValue} required max={Todaysdate} />
                        </div>
                      </div>
                    </div>
                    <div className="field-group second-section" key="payee">
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="expense_trans_vendor_id" className="label">Vendor<span className="mandatory-field">* </span></label>
                         
                          <Select options={payee}
                            isClearable={isClearable}
                            getOptionLabel={e => e.cc_trans_vendor_name}
                            getOptionValue={e => e.cc_trans_vendor_id}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix" name="expense_trans_vendor_id"
                            required
                            onChange={data => onChangeGetValue({ target: { value: data?.cc_trans_vendor_id, name: 'expense_trans_vendor_id' } })}


                          />
                        </div>

                      </div>
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="expense_trans_amt" className="label">Amount<span className="mandatory-field">* </span></label>

                          <CurrencyInput
                            id="input-example"
                            name="expense_trans_amt"
                            decimalsLimit={2}
                            prefix="$ "
                            allowDecimals
                            placeholder="$ 0.00"
                            allowNegativeValue={false}
                            required
                            key='amount_key'
                            onValueChange={(value, name) => onChangeGetValue({ target: { value: value, name: 'expense_trans_amt' } })}
                          />
                          
        {inputField.expense_trans_amt == 0 &&
                            <span className="error-msg">Please enter &gt; 0 value</span>}
                        </div>
                      </div>
                      <div className="field-element">
                        <label htmlFor="expense_trans_memo" className="label">Description<span className="mandatory-field">* </span></label>
                        <div className="field">
                          <input name="expense_trans_memo" id="memo" type="text" required onChange={onChangeGetValue} />
                        </div>
                      </div>

                      <div className="field-element">
                        <label htmlFor="expense_trans_ticket_number" className="label">Ticket Number</label>
                        <div className="field">
                          <input name="expense_trans_ticket_number" id="ticketNumber" value={ticketNumber} type="text" onChange={onChangeticketNumber} />
                        </div>
                      </div>
                    </div>

                    <div className="field-group third-section">
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="memo" className="label">Bill To<span className="mandatory-field">* </span></label>
                          <Select options={billTo}
                            onChange={(e) => selectBill(e)}
                            isClearable={isClearable}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="expense_trans_bill_to"
                            required                          

                          />
                        </div></div>
                      {billOption == 1 ? (
                        <div className="field-element" key="client">
                          <div className="field">
                            <label htmlFor="expense_trans_bill_to_client_id" className="label">Client<span className="mandatory-field">* </span></label>
                            <Select options={clients}
                              isClearable={isClearable}
                              getOptionLabel={e => e.client_name}
                              getOptionValue={e => e.client_id}
                              onChange={(e) => selectClient(e)}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="expense_trans_bill_to_client_id"                             
                              required

                            />
                          </div>
                        </div>) :
                        billOption == 2 ? (
                          <div className="field-element" key="department">
                            <div className="field">
                              <label htmlFor="memo" className="label">Purchase Type</label>
                              <Select options={purchaseType}
                                isClearable={isClearable}
                                getOptionLabel={e => e.cc_trans_purchase_type}
                                getOptionValue={e => e.cc_trans_purchase_type_id}
                                onChange={(e) => selectPurchaseType(e)}
                                className="form-custom-class"
                                classNamePrefix="form-custom-class-prefix"
                                name="expense_trans_purchase_type_id"


                              />
                            </div>
                          </div>) : null}
                      
                      {/*client options*/}

                      {(billOption == 1 && projects.length > 0 && clientOption && (clientExpTaskLevel != null && clientExpTaskLevel != 0)) ? (

                        <div className="field-element" key="Projects">
                          <div className="field">
                            <label htmlFor="memo" className="label">Project<span className="mandatory-field">* </span></label>

                            <Select options={projects}
                              isClearable={isClearable}
                              getOptionLabel={e => e.project_name}
                              getOptionValue={e => e.project_id}
                              onChange={(e) => selectProject(e)}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="expense_trans_bill_to_project_id"                           
                              required
                            />
                          </div>
                        </div>) : (<div className="field-element"></div>)}

                      {/* client options*/}
                    </div>

                    {/* {new dropdown} */}

                    <div className="field-group">
                      {(billOption == 1 && tasks.length > 0 && projectOption && clientOption && projects.length > 0 && (clientExpTaskLevel != null && clientExpTaskLevel != 0)) ? (
                        <div className="field-element">
                          <div className="field">
                            <label htmlFor="memo" className="label">Task<span className="mandatory-field">* </span></label>
                            <Select options={tasks}
                              onChange={(e) => selectTasks(e)}
                              isClearable={isClearable}
                              getOptionLabel={e => e.task_name}
                              getOptionValue={e => e.ts_task_id}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="bill_to"
                              required

                            />
                          </div></div>) : <div></div>}

                      {(billOption == 1 && subtasks.length > 0 && taskOption && projectOption && clientOption && projects.length > 0) && (clientExpTaskLevel != null && clientExpTaskLevel != 0) ? (
                        <div className="field-element">
                          <div className="field">
                            <label htmlFor="memo" className="label">Sub Task<span className="mandatory-field">* </span></label>
                            <Select options={subtasks}
                              onChange={(e) => selectSubTasks(e)}
                              isClearable={isClearable}
                              getOptionLabel={e => e.task_name}
                              getOptionValue={e => e.ts_task_id}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="bill_to"
                              required

                            />
                          </div></div>) : (
                        <div className="field-element"></div>)}

                      <div className="field-element"></div>
                    </div>
                    {/* {tasks end} */}

                    {/* {upload}  */}
                    <div className='button-message'>
                      <div className="upload-images-div">

                        <div className="desk-view-r">
                          <div className="field-group">

                            <div className="field">
                              <div className="button-wrap" id='image-upload'>
                                
                                {/* image upload */}
                                <ImageUploading
                                  value={images}
                                  onChange={onChange}
                                  maxNumber={maxNumber}
                                  dataURLKey="data_url"
                                  acceptType={['jpg', 'jpeg', 'pdf', 'png', 'html']}
                                  allowNonImageType={true}

                                >
                                  {({
                                    imageList,
                                    onImageUpload,
                                    isDragging,
                                    dragProps,
                                  }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                      <div className='upload-button reimbursement'
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        {/* PDF Receipt */}
                                        <span className='mobile-view'><img src={'/assets/images/camera-small.png'} /> *</span> <span className='desk-view'>ATTACH REIMBURSEMENT RECEIPT *</span>
                                      </div>
                                      &nbsp;
                                      {imageList.map((image, index) => (

                                        <div key={index} className="image-item">
                                          {image['file']?.type == "application/pdf" ? (
                                            <div><img src={'assets/images/PDF_file_icon.png'} className="App-logo" alt="logo" />
                                            </div>) : image['file']?.type == "text/html" ?
                                            (<div><img src={'assets/images/html_icon.png'} className="App-logo" alt="logo" />
                                            </div>) : (
                                              <div>
                                                <img src={image['data_url']} alt="" width="100" />
                                                
                                              </div>)}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </ImageUploading>
                                {/* image upload */}

                              </div>
                            </div>
                          </div>
                        </div>
                        {/* {upload end} */}


                        <div className="field-group">
                          <div className="field">
                            <i className='file-format'>Image, PDF &amp; HTML files allowed</i>
                            <div className='receive-save-wrapper'>
                              <div className="sign-btn flex">
                                <button type="submit" className="btn btn-primary receipt-save">
                                  Submit</button>
                               
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="field-group">
                     
                        <div className="error-msg alert alert-success mt-3 mb-0">{saveError}</div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </main>
         

        </div>
      </div>

    </>
  );
}


