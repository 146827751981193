import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './ExpenseReimbursementFilterDashboard.scss';
import { userActions } from '../../_store';
import { clientsActions } from '../../_store';
import { payeeActions } from '../../_store';
import { tasksActions } from '../../_store';
import { subtasksActions } from '../../_store';
import Select from 'react-select';
import { Navigate } from 'react-router-dom';
import { history } from '../../_helpers';
import CurrencyInput from '../../_components/CurrencyInput.tsx'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import { reimbursementDashboardActions } from "../../_store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { purchaseTypeActions } from '../../_store';
import { projectsActions } from '../../_store';
import Spinner from 'react-bootstrap/Spinner';



const billTo = [
  { value: 'C', label: 'Client', key: 1 },
  { value: 'T', label: 'Tek', key: 2 },
]
const creditDD = [
  { value: 'Charge', label: 'Charge', key: true },
  { value: 'Credit', label: 'Credit', key: false },
  { value: 'null', label: '', key: null },
]

export { ReimbursementFilterDashboard };

function ReimbursementFilterDashboard(props) {
  useEffect(() => {
    dispatch(userActions.getAll());
    dispatch(clientsActions.getAll());
    dispatch(payeeActions.getAll());
    dispatch(purchaseTypeActions.getAll());
  }, []);

  const dispatch = useDispatch();
  const { user: authUser } = useSelector(x => x.auth);
  const { users } = useSelector(x => x.users);
  const { payee } = useSelector(x => x.payee);
  const { clients } = useSelector(x => x.clients);
  let { projects } = useSelector(x => x.projects);
  let { tasks } = useSelector(x => x.tasks);
  let { subtasks } = useSelector(x => x.subtasks);
  let { purchaseType } = useSelector(x => x.purchaseType);
  const [ticketNumber, setTicketNumber] = useState('');
  const [billOption, selectBillTo] = useState();
  const [clientOption, setClient] = useState();
  const [projectOption, setProject] = useState();
  const [taskOption, setTask] = useState();
  const [clientExpTaskLevel, setClientExpTaskLevel] = useState(null);
  const [subTaskId, setSubTaskId] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [vendorId, setVendorId] = useState('');
  const [usedById, setUsedById] = useState('');
  const [uploadedById, setUploadedById] = useState('');
  const [projectClear, setProjectClear] = useState(true);
  const [taskClear, setTaskClear] = useState(true);
  const [clientClear, setClientClear] = useState(true);
  const [uploadedStartDate, setUploadedStartDate] = useState(null);
  const [endUploadedDate, setEndUploadedDate] = useState(null);

  const vendorRef = useRef();
  // const uploadBy = useRef();
  const userRef = useRef();
  const tDateFromRef = useRef();
  const tDateToRef = useRef();
  const uDateFromRef = useRef();
  const uDateToRef = useRef();
  const ticketRef = useRef();
  const billToRef = useRef();
  const clientRef = useRef();
  const purchaseRef = useRef();
  const personRef = useRef();
  const chargeRef = useRef();

  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
  }

  let userDefaultValue = {
    user_active: authUser.user.user_active,
    user_fname: authUser.user.user_fname,
    user_id: authUser.user.user_id,
    user_lname: authUser.user.user_lname,
    user_mname: authUser.user.user_mname
  }
  let chargeDefaultValue = creditDD[0];
  let bindFilterObjValues;

  bindFilterObjValues = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'));
  chargeDefaultValue = creditDD.find(obj => {
    return obj.key === bindFilterObjValues.isCharge;
  })

  if (users.length > 0) {
    userDefaultValue = users.find(obj => {
      return obj.user_id === bindFilterObjValues.expense_trans_user_id;
    })
  }

  const vendorData = (e) => {
    if (e) {
      setVendorId(() => e.cc_trans_vendor_id);
      inputField.expense_trans_vendor_id = e.cc_trans_vendor_id;
    } else {
      inputField.expense_trans_vendor_id = null;
    }
  }

  const usedByData = (e) => {
    if (e) {
      setUsedById(() => e.user_id);
      inputField.expense_trans_user_id = e.user_id;
    } else {
      inputField.expense_trans_user_id = '';
    }
  }

  // const uploadedByData = (e) => {
  //   if (e) {
  //     setUploadedById(() => e.user_id);
  //     inputField.expense_trans_uploaded_by = e.user_id;
  //   } else {
  //     inputField.expense_trans_uploaded_by = '';

  //   }
  // }

  const transStartDate = (data) => {
    setStartDate(data);
    if (data !== null) {
      inputField.expense_trans_from_dt = moment(data).format('MM/DD/YYYY')
    } else {
      inputField.expense_trans_from_dt = null;
    }
  }

  const transEndDate = (data) => {
    setEndDate(data);
    if (data !== null) {
      inputField.expense_trans_to_dt = moment(data).format('MM/DD/YYYY')
    } else {
      inputField.expense_trans_to_dt = null;
    }
  }

  const onUploadedStartDate = (data) => {
    setUploadedStartDate(data);
    inputField.expense_trans_uploaded_from_dt = (data !== null) ? moment(data).format('MM/DD/YYYY') : null
  }

  const onEendUploadedDate = (data) => {
    setEndUploadedDate(data);
    inputField.expense_trans_uploaded_to_dt = (data !== null) ? moment(data).format('MM/DD/YYYY') : null;
  }

  const selectBill = (e) => {
    if (e) {
      selectBillTo(e.key);
      onChangeGetValue({ target: { value: e?.value, name: 'expense_trans_bill_to' } });
      if (e.value === 'C') {
        purchaseRef.current?.setValue(null);
        inputField.expense_trans_purchase_type_id = '';
        onChangeGetValue({ target: { value: '', name: 'expense_trans_purchase_type_id' } });
      }
      else if (e.value === 'T') {
        clientRef.current?.setValue(null);
        inputField.expense_trans_purchase_type_id = '';
        onChangeGetValue({ target: { value: '', name: 'expense_trans_purchase_type_id' } });
    } 
  }else {
      selectBillTo(undefined);
      onChangeGetValue({ target: { value: '', name: 'expense_trans_bill_to' } });
      purchaseRef.current?.setValue(null);
      inputField.expense_trans_purchase_type_id = '';
      onChangeGetValue({ target: { value: '', name: 'expense_trans_purchase_type_id' } });
    }
  };
  
  const selectPurchaseType = (e) => {
    if (e) {
      onChangeGetValue({ target: { value: e?.cc_trans_purchase_type_id, name: 'expense_trans_purchase_type_id' } });
      inputField.expense_trans_bill_to_user_id = '';
      inputField.expense_trans_bill_to_client_id = '';
      if (billOption == 2) {
        onChangeGetValue({ target: { value: e?.cc_trans_purchase_type_id, name: 'expense_trans_purchase_type_id' } });
        inputField.expense_trans_bill_to_user_id = '';
        inputField.expense_trans_bill_to_client_id = '';
      }
    } else {
      onChangeGetValue({ target: { value: '', name: 'expense_trans_purchase_type_id' } });
    }
};

  const selectClient = (e) => {
    if (e) {
      setClientExpTaskLevel(e.client_exp_at_task_level);
      setClientClear(true)
      const param = e.client_id;
      setClient(e.client_id);
      onChangeGetValue({ target: { value: e?.client_id, name: 'expense_trans_bill_to_client_id' } })
      inputField.expense_trans_purchase_type_id = '';
      inputField.expense_trans_bill_to_user_id = '';
      if (e.client_exp_at_task_level != null) {
        dispatch(projectsActions.getAllById({ param }));
      } else {
        inputField.expense_trans_bill_to_project_id = '';
        inputField.expense_trans_bill_to_sub_task_id = '';
        inputField.expense_trans_bill_to_task_id = '';
        projects = []
      }
      if (billOption == 1) {
        inputField.expense_trans_purchase_type_id = '';
        inputField.expense_trans_bill_to_user_id = '';
      }
    } else {
      setClientClear(false)
      projects = [];
      tasks = [];
      setClient(undefined)
      onChangeGetValue({ target: { value: e?.client_id, name: 'expense_trans_bill_to_client_id' } })
      inputField.expense_trans_bill_to_project_id = '';
      inputField.expense_trans_bill_to_task_id = '';
      inputField.expense_trans_bill_to_sub_task_id = '';
    }
  };

  function showProject() {
    if (bindFilterObjValues.expense_trans_bill_to_client_id && clients.length > 0) {
      let getClientObj = clients.find(obj => obj.client_id == bindFilterObjValues.expense_trans_bill_to_client_id)
      const param = getClientObj?.client_id;
      if (getClientObj.client_exp_at_task_level != null) {
        setClientExpTaskLevel(getClientObj.client_exp_at_task_level)
        dispatch(projectsActions.getAllById({ param }));
      }
    }
  }

  useEffect(() => {
    showProject();
  }, [clients])


  const selectProject = (e) => {
    if (e) {
      setProjectClear(true);
      const param = e.project_id;
      setProject(e.project_id)
      dispatch(tasksActions.getAllById({ param }));
      onChangeGetValue({ target: { value: e?.project_id, name: 'expense_trans_bill_to_project_id' } })
    } else {
      tasks = [];
      setProjectClear(false);
      setProject(undefined)
      inputField.expense_trans_bill_to_project_id = '';
      inputField.expense_trans_bill_to_task_id = '';
      inputField.expense_trans_bill_to_sub_task_id = '';
    }
  };

  function showTask() {
    if (bindFilterObjValues.expense_trans_bill_to_project_id && projects.length > 0) {
      let getProjectObj = projects.find(obj => obj.project_id == bindFilterObjValues.expense_trans_bill_to_project_id)
      const param = getProjectObj?.project_id;
      if (param !== undefined) {
        dispatch(tasksActions.getAllById({ param }));
      }

    }

  }

  useEffect(() => {
    showTask();
  }, [projects])

  const selectTasks = (e) => {
    setTaskClear(true)
    onChangeGetValue({ target: { value: e?.ts_task_id, name: 'expense_trans_bill_to_task_id' } });
    if (e) {
      const param = e.ts_task_id;
      setTask(e.ts_task_id);
      dispatch(subtasksActions.getAllById({ param }));

    } else {
      setTaskClear(false)
      subtasks = [];
      inputField.expense_trans_bill_to_sub_task_id = '';
      setTask(undefined)
    }
  };

  const selectSubTasks = (e) => {
    onChangeGetValue({ target: { value: e?.ts_task_id, name: 'expense_trans_bill_to_sub_task_id' } });
    if (e) {
      setSubTaskId(e.ts_task_id);
    }
  };
  function showSubTask() {
    if (!projectOption) {
      if (bindFilterObjValues.expense_trans_bill_to_task_id && tasks.length > 0) {
        let getTaskObj = tasks.find(obj => obj.ts_task_id == bindFilterObjValues.expense_trans_bill_to_task_id)
        const param = getTaskObj?.ts_task_id;
        if (param !== undefined) {
          dispatch(subtasksActions.getAllById({ param }));
        }
      }
    }
  }
  useEffect(() => {
    showSubTask();
  }, [tasks])
  const onChangeticketNumber = (e) => {
    const value = e.target.value?.replace(/\D/g, "");
    setTicketNumber(value);
    inputField.expense_trans_ticket_number = value;
  };
  const [inputField, setInputField] = useState({

    expense_trans_user_id: usedById ? usedById : bindFilterObjValues.expense_trans_user_id,
    // expense_trans_uploaded_by: uploadedById ? uploadedById : bindFilterObjValues.expense_trans_uploaded_by,
    expense_trans_uploaded_by: '',
    expense_trans_from_dt: startDate ? moment(startDate).format('MM/DD/YYYY') : bindFilterObjValues.expense_trans_from_dt,
    expense_trans_to_dt: endDate ? moment(endDate).format('MM/DD/YYYY') : bindFilterObjValues.expense_trans_to_dt,
    expense_trans_uploaded_from_dt: uploadedStartDate ? moment(uploadedStartDate).format('MM/DD/YYYY') : bindFilterObjValues.expense_trans_uploaded_from_dt,
    expense_trans_uploaded_to_dt: endUploadedDate ? moment(endUploadedDate).format('MM/DD/YYYY') : bindFilterObjValues.expense_trans_uploaded_to_dt,
    expense_trans_vendor_id: vendorId ? vendorId : bindFilterObjValues.expense_trans_vendor_id,
    expense_trans_amt: bindFilterObjValues.expense_trans_amt,
    expense_trans_memo: '',
    expense_trans_ticket_number: bindFilterObjValues.expense_trans_ticket_number,
    expense_trans_bill_to: bindFilterObjValues.expense_trans_bill_to ? bindFilterObjValues.expense_trans_bill_to : '',
    expense_trans_bill_to_client_id: bindFilterObjValues.expense_trans_bill_to == 'C' ? bindFilterObjValues.expense_trans_bill_to_client_id : '',
    expense_trans_bill_to_project_id: (bindFilterObjValues.expense_trans_bill_to == 'C' && bindFilterObjValues.expense_trans_bill_to_client_id) ? bindFilterObjValues.expense_trans_bill_to_project_id : '',
    expense_trans_purchase_type_id: bindFilterObjValues.expense_trans_bill_to == 'T' ? bindFilterObjValues.expense_trans_purchase_type_id : '',
    expense_trans_bill_to_user_id: bindFilterObjValues.expense_trans_bill_to == 'P' ? bindFilterObjValues.expense_trans_bill_to_user_id : '',
    expense_trans_bill_to_task_id: (bindFilterObjValues.expense_trans_bill_to == 'C' && bindFilterObjValues.expense_trans_bill_to_project_id && bindFilterObjValues.expense_trans_bill_to_client_id) ? bindFilterObjValues.expense_trans_bill_to_task_id : '',
    expense_trans_bill_to_sub_task_id: (bindFilterObjValues.expense_trans_bill_to == 'C' && bindFilterObjValues.expense_trans_bill_to_project_id && bindFilterObjValues.expense_trans_bill_to_client_id && bindFilterObjValues.expense_trans_bill_to_task_id) ? bindFilterObjValues.expense_trans_bill_to_sub_task_id : '',
    isCharge: bindFilterObjValues.isCharge !== undefined ? bindFilterObjValues.isCharge : true,
    sortColumn: bindFilterObjValues.sortColumn,
    sortOrder: bindFilterObjValues.sortOrder,
    pageNumber: 1,
    pageSize: 25

  });
  const onChangeGetValue = e => {
    const { name, value } = e.target;
    setInputField(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const amountChange = (e) => {
    let amountString = e.target.value;
    const amountStringValue = amountString.slice(1);
   // inputField.expense_trans_amt = amountStringValue;
   if(inputField.isCharge == true || inputField.isCharge == null){
    inputField.expense_trans_amt = amountStringValue ? Math.abs(amountStringValue): null;
    } else{      
      inputField.expense_trans_amt = amountStringValue 
      ? (!String(amountStringValue).trim().startsWith('-') ? '-' + String(amountStringValue).trim() : String(amountStringValue).trim()) 
      : null;
  
  

    }
  }

  let copyLocalObj = {};
  const handleSubmit = (event) => {
    event.preventDefault();
    props.sdata.onHide();
    if( inputField.isCharge || inputField.isCharge == null) {
      inputField.expense_trans_amt = inputField.expense_trans_amt ? Math.abs(inputField.expense_trans_amt) : inputField.expense_trans_amt;
    } else{
      inputField.expense_trans_amt = inputField.expense_trans_amt 
      ? (!String(inputField.expense_trans_amt).trim().startsWith('-') ? '-' + String(inputField.expense_trans_amt).trim() : String(inputField.expense_trans_amt).trim()) 
      : null;
    }
    localStorage.setItem('reimbersementFilterObjValues', JSON.stringify(inputField));
    props.sdata.setcurrentpage(1);
    props.sdata.setrecordsperpage(25)
    copyLocalObj = Object.assign({}, JSON.parse(localStorage.getItem('reimbersementFilterObjValues')));
    localStorage.setItem('reimbersementcopyLocalObj', JSON.stringify(copyLocalObj));
    dispatch(reimbursementDashboardActions.getAll(inputField));
    inputField.pageNumber = '';
    inputField.pageSize = '';
  }



  const cancelModal = () => {
    props.sdata.onHide();
    if (localStorage.getItem('reimbersementcopyLocalObj')) {
      const resetForm = JSON.parse(localStorage.getItem('reimbersementcopyLocalObj'))
      localStorage.setItem('reimbersementFilterObjValues', JSON.stringify(resetForm));
    }
  }
  const resetFilter = () => {
    let Filterobj = {
      expense_trans_user_id: authUser.user.user_id,
      expense_trans_uploaded_by: '',
      expense_trans_from_dt: null,
      expense_trans_to_dt: null,
      expense_trans_uploaded_from_dt: null,
      expense_trans_uploaded_to_dt: null,
      expense_trans_vendor_id: null,
      expense_trans_amt: '',
      expense_trans_memo: '',
      expense_trans_ticket_number: '',
      expense_trans_bill_to: '',
      expense_trans_bill_to_client_id: '',
      expense_trans_bill_to_project_id: '',
      expense_trans_purchase_type_id: '',
      expense_trans_bill_to_user_id: '',
      expense_trans_bill_to_task_id: '',
      expense_trans_bill_to_sub_task_id: '',
      sortColumn: '',
      sortOrder: '',
      pageNumber: 1,
      pageSize: 25
    }
    localStorage.setItem('reimbersementFilterObjValues', JSON.stringify(Filterobj));
    bindFilterObjValues = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'));
    const resetUser = {
      user_active: authUser.user.user_active,
      user_fname: authUser.user.user_fname,
      user_id: authUser.user.user_id,
      user_lname: authUser.user.user_lname,
      user_mname: authUser.user.user_mname
    }
    uDateFromRef.current.clear(); 
    uDateToRef.current.clear();

    userRef.current.setValue(resetUser);
    // uploadBy.current.setValue(resetUser);
    vendorRef.current.setValue(null);
    inputField.expense_trans_vendor_id = null;
    tDateFromRef.current.setSelected(null);
    document.getElementById("test").value = '';
    setStartDate(null);
    inputField.expense_trans_from_dt = '',
      setEndDate(null);
    inputField.expense_trans_to_dt = '',
      setEndDate(null);
    inputField.expense_trans_uploaded_from_dt = '',
      setEndUploadedDate(null);
    inputField.expense_trans_uploaded_to_dt = '',
      billToRef.current?.setValue('');
    inputField.expense_trans_bill_to = '';
    clientRef.current?.setValue('');
    inputField.expense_trans_bill_to_client_id = '';
    purchaseRef.current?.setValue('');
    inputField.expense_trans_purchase_type_id = '';
    personRef.current?.setValue('');
    inputField.expense_trans_bill_to_user_id = '';
    ticketRef.current.value = '';
    chargeRef.current?.setValue(creditDD[0]);
    setTicketNumber('');
    if (ticketRef.current) {
        ticketRef.current.value = '';
    }
    onChangeGetValue({ target: { value: '', name: 'expense_trans_amt' } })
    inputField.expense_trans_amt = '';
    document.getElementById("input-example").removeAttribute("value");
    document.getElementById("ticketNumber").value = '';
    inputField.expense_trans_ticket_number = '';
  }

  return (

    <div className="form-overlay">

      <div className="receipts edit-receipts">
        {(subtasks.loading || tasks.loading || projects.loading || users.loading || payee.loading) &&
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>}

        <form onSubmit={handleSubmit}>

          <div className='filter-dashboard'>
            {users.length > 0 && payee.length > 0 &&
              (bindFilterObjValues.expense_trans_bill_to_project_id ? projects.length > 0 : true) &&
              (bindFilterObjValues.expense_trans_bill_to_client_id ? clients.length > 0 : true) &&
              (bindFilterObjValues.expense_trans_bill_to_task_id ? tasks.length > 0 : true) &&
              (bindFilterObjValues.expense_trans_bill_to_sub_task_id ? subtasks.length > 0 : true) &&
              <div>
                <div className="field-group second-section">
                <div className="field-element">
                    <div className="field">
                      <div className="label">Type</div>
                      {creditDD.length > 0 &&
                        <Select options={creditDD.filter(option => option.label !== '')}
                          isClearable
                          getOptionLabel={e => e.label}
                          getOptionValue={e => e.key}
                          defaultValue={chargeDefaultValue}
                          // defaultValue={creditDD.find(obj => obj.key == bindFilterObjValues.isCharge)}
                          isSearchable={false}
                          className="form-custom-class cc-class"
                          classNamePrefix="form-custom-class-prefix" name="isCharge"
                          ref={chargeRef}
                          // getOptionLabel={getOptionLabel}
                          onChange={data => onChangeGetValue({ target: { value: data?.key, name: 'isCharge' } })}
                        />
                      }
                    </div>
                  </div>
                  <div className="field-element">
                    <div className="field">
                      <div className="label">EMPLOYEE NAME</div>
                      {users.length > 0 &&
                        <Select options={users}
                          isClearable
                          getOptionLabel={e => e.user_id}
                          getOptionValue={e => e.user_id}
                          defaultValue={userDefaultValue}
                          className="form-custom-class current-user"
                          classNamePrefix="form-custom-class-prefix" name="expense_trans_user_id"
                          ref={userRef}
                          onChange={usedByData}
                        />
                      }
                    </div>
                  </div>

                  {/* <div className="field-element">
                    <div className="field">
                      <div className="label">Uploaded By</div>
                      {users.length > 0 &&
                        <Select options={users}
                          isClearable
                          getOptionLabel={e => e.expense_trans_uploaded_by}
                          getOptionValue={e => e.expense_trans_uploaded_by}
                          defaultValue={users.find(obj => obj.user_id == bindFilterObjValues.expense_trans_uploaded_by)}
                          className="form-custom-class current-user"
                          classNamePrefix="form-custom-class-prefix" name="expense_trans_uploaded_by"
                          ref={uploadBy}
                          onChange={uploadedByData}
                        />
                      }


                    </div>
                  </div> */}
                  <div className="field-element">
                    <div className="field">
                      <label htmlFor="vendor" className="label">Vendor</label>
                      {payee.length > 0 &&
                        <Select options={payee}
                          isClearable
                          getOptionLabel={e => e.cc_trans_vendor_name}
                          getOptionValue={e => e.cc_trans_vendor_id}
                          defaultValue={payee.find(obj => obj.cc_trans_vendor_id == bindFilterObjValues.expense_trans_vendor_id)}
                          className="form-custom-class"
                          classNamePrefix="form-custom-class-prefix" name="expense_trans_vendor_id"
                          ref={vendorRef}
                          onChange={vendorData}
                        />
                      }
                    </div>

                  </div>
                  <div className="field-element"></div>
                </div>
                <div className="field-group second-section" key="payee">
                  <div className="field-element">
                    <div className="field input-margin">
                      <label htmlFor="expense_trans_from_dt" className="label">Transaction Date Range</label>
                      <div className='d-range'>
                        <DatePicker
                          onChange={(date) => transStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          maxDate={new Date()}
                          className='fm-date'
                          id="test"
                          selected={startDate ? startDate : (bindFilterObjValues.expense_trans_from_dt ? new Date(bindFilterObjValues.expense_trans_from_dt) : null)}
                          ref={tDateFromRef}
                        />
                        <DatePicker
                          selected={endDate ? endDate : (bindFilterObjValues.expense_trans_to_dt ? new Date(bindFilterObjValues.expense_trans_to_dt) : null)}
                          onChange={(date) => transEndDate(date)}
                          selectsEnd
                          maxDate={new Date()}
                          endDate={endDate}
                          minDate={startDate}
                          className='to-date'
                          ref={tDateToRef}
                        />
                      </div>
                    </div>


                  </div>

                  <div className="field-element">
                    <div className="field input-margin">
                      <label htmlFor="expense_trans_uploaded_from_dt" className="label">Uploaded Date Range</label>
                      <div className='d-range'>
                        <DatePicker
                          selected={uploadedStartDate ? uploadedStartDate : (bindFilterObjValues.expense_trans_uploaded_from_dt ? new Date(bindFilterObjValues.expense_trans_uploaded_from_dt) : null)}
                          onChange={(date) => onUploadedStartDate(date)}
                          selectsStart
                          maxDate={new Date()}
                          startDate={uploadedStartDate}
                          className='fm-date'
                          ref={uDateFromRef}
                        />
                        <DatePicker
                          selected={endUploadedDate ? endUploadedDate : (bindFilterObjValues.expense_trans_uploaded_to_dt ? new Date(bindFilterObjValues.expense_trans_uploaded_to_dt) : null)}
                          onChange={(date) => onEendUploadedDate(date)}
                          selectsEnd
                          maxDate={new Date()}
                          endDate={endUploadedDate}
                          minDate={uploadedStartDate}
                          className='to-date'
                          ref={uDateToRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="field-element">
                    <div className="field">
                      <label htmlFor="expense_trans_amt" className="label">Amount</label>
                      <CurrencyInput placeholder="$ 0.00" id="input-example" name="expense_trans_amt"
                        type="text" onChange={amountChange}
                        defaultValue={bindFilterObjValues.expense_trans_amt ? bindFilterObjValues.expense_trans_amt : ''}
                      />
                    </div>
                  </div>
                  <div className="field-element">
                    <label htmlFor="expense_trans_ticket_number" className="label">Ticket Number</label>
                    <div className="field">
                      <input name="expense_trans_ticket_number" id="ticketNumber" defaultValue={bindFilterObjValues.expense_trans_ticket_number ? bindFilterObjValues.expense_trans_ticket_number : ticketNumber} type="text" onChange={onChangeticketNumber} ref={ticketRef} />
                    </div>
                  </div>
                </div>

                {/* {3RD ROW} */}
                <div className="field-group third-section">

                  <div className="field-element">
                    <div className="field">
                      <label htmlFor="memo" className="label">Bill To</label>
                      <Select options={billTo}
                        onChange={(e) => selectBill(e)}
                        isClearable
                        ref={billToRef}
                        className="form-custom-class"
                        classNamePrefix="form-custom-class-prefix"
                        name="expense_trans_bill_to"
                        defaultValue={billTo.find(obj => obj.value == bindFilterObjValues.expense_trans_bill_to)}
                      />
                    </div></div>
                  {(billOption == 1 || bindFilterObjValues.expense_trans_bill_to == 'C') && (billOption != 2 && billOption != 3) ? (
                    <div className="field-element" key="client">
                      <div className="field">
                        <label htmlFor="client" className="label">Client</label>
                        {clients.length > 0 &&
                          <Select options={clients}
                            isClearable
                            ref={clientRef}
                            getOptionLabel={e => e.client_name}
                            getOptionValue={e => e.client_id}
                            onChange={(e) => selectClient(e)}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="expense_trans_bill_to_client_id"
                            defaultValue={clients.find(obj => obj.client_id == bindFilterObjValues.expense_trans_bill_to_client_id)}
                          />
                        }


                      </div>
                    </div>) :
                    (billOption == 2 || bindFilterObjValues.expense_trans_bill_to == 'T') && (billOption != 1 && billOption != 3) ? (

                      <div className="field-element" key="purchase_type">

                        <div className="field">
                          <label htmlFor="memo" className="label">Purchase Type</label>
                          {purchaseType.length > 0 &&
                            <Select options={purchaseType}
                              isClearable
                              ref={purchaseRef}
                              getOptionLabel={e => e.cc_trans_purchase_type}
                              getOptionValue={e => e.cc_trans_purchase_type_id}
                              onChange={(e) => selectPurchaseType(e)}
                              defaultValue={purchaseType.find(obj => obj.cc_trans_purchase_type_id == bindFilterObjValues.expense_trans_purchase_type_id)}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="expense_trans_purchase_type_id"

                            />}
                        </div>
                      </div>) :
                      
                      (<div>

                      </div>)}
                  {/* client options*/}
                  
                  {((clientClear && (clientExpTaskLevel != null && clientExpTaskLevel != 0) && bindFilterObjValues.expense_trans_bill_to_project_id && bindFilterObjValues.expense_trans_bill_to == 'C') || (projects.length > 0 && clientOption && (clientExpTaskLevel != null && clientExpTaskLevel != 0))) && (billOption != 2 && billOption != 3) ? (

                    <div className="field-element" key="Projects">
                      <div className="field">
                        <label htmlFor="memo" className="label">Project</label>
                        {projects.length > 0 &&
                          <Select options={projects}
                            isClearable
                            getOptionLabel={e => e.project_name}
                            getOptionValue={e => e.project_id}
                            onChange={(e) => selectProject(e)}
                            defaultValue={projects.find(obj => obj.project_id == bindFilterObjValues.expense_trans_bill_to_project_id)}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="expense_trans_bill_to_project_id"


                          />}
                      </div>
                    </div>) : (<div className="field-element"></div>)}

                  {/* client options*/}
                </div>

                {/* {new dropdown} */}
                <div className="field-group">
                  {(((clientClear && (clientExpTaskLevel != null && clientExpTaskLevel != 0) && projectClear && bindFilterObjValues.expense_trans_bill_to_task_id && bindFilterObjValues.expense_trans_bill_to == 'C') || (tasks.length > 0 && projectOption && clientOption && projects.length > 0)) && (billOption != 2 && billOption != 3)) ? (
                    <div className="field-element">
                      <div className="field">
                        <label htmlFor="memo" className="label">Task</label>
                        {tasks.length > 0 &&
                          <Select options={tasks}
                            onChange={(e) => selectTasks(e)}
                            isClearable
                            getOptionLabel={e => e.task_name}
                            getOptionValue={e => e.ts_task_id}
                            defaultValue={tasks.find(obj => obj.ts_task_id == bindFilterObjValues.expense_trans_bill_to_task_id)}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="bill_to"
                          />}
                      </div></div>) : <div></div>}

                  {(((clientClear && (clientExpTaskLevel != null && clientExpTaskLevel != 0) && projectClear && taskClear && bindFilterObjValues.expense_trans_bill_to_sub_task_id && bindFilterObjValues.expense_trans_bill_to == 'C') || (tasks.length > 0 && subtasks.length > 0 && clientOption && taskOption && projectOption && projects.length > 0)) && (billOption != 2 && billOption != 3)) ? (
                    <div className="field-element">
                      <div className="field">
                        <label htmlFor="memo" className="label">Sub Task</label>
                        {subtasks.length > 0 &&
                          <Select options={subtasks}
                            onChange={(e) => selectSubTasks(e)}
                            isClearable
                            getOptionLabel={e => e.task_name}
                            getOptionValue={e => e.ts_task_id}
                            defaultValue={subtasks.find(obj => obj.ts_task_id == bindFilterObjValues.expense_trans_bill_to_sub_task_id)}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="bill_to"
                          />}
                      </div></div>) : (
                    <div className="field-element"></div>)}

                  <div className="field-element"></div>
                </div>
                {/* {tasks end} */}

           
                {/* {3rd row ends here} */}

              </div>
            }

          </div>
          <div className='flex'>
            <OverlayTrigger
              key='bottom-filter'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-filter'>
                  Apply Filters
                </Tooltip>
              }
            >
              <Button type="submit"> <img src={'/assets/icons/checkmark.svg'} /></Button>

            </OverlayTrigger>
            <OverlayTrigger
              key='bottom-cancel'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-cancel'>
                  Cancel
                </Tooltip>
              }
            >
              <Button onClick={cancelModal} className="close-button"> <img src={'/assets/icons/close.svg'} /></Button>
            </OverlayTrigger>
            <OverlayTrigger
              key='bottom-reset'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-reset'>
                  Reset
                </Tooltip>
              }
            >
              <Button onClick={resetFilter} type="reset" defaultValue="Reset" className="reset-button"> <img src={'/assets/icons/reset.svg'} /></Button>
            </OverlayTrigger>
          </div>
        </form>
      </div>
    </div>
  );
}

