import React, { useEffect, useState } from 'react';
import './ExpenseReimbursementDashboard.scss';
import { FormModal } from '../../_components/FormModal';
import { FilterModal } from '../../_components/FilterModal';
import { ReimbursementExportModal } from '../../_components/ExpenseReimbursementExportModal';
import { ReimbursementDeleteModal } from '../../_components/ReimbursementDeleteModal';
import { reimbursementDashboardActions } from "../../_store";
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import { Navigate } from 'react-router-dom';
import Pagination from '../../_components/PaginationRange';
import { formatValue } from 'react-currency-input-field';

export { ReimbursementDashboard }
function ReimbursementDashboard() {
    const { user: authUser } = useSelector(x => x.auth);
    if (!authUser) {
        return <Navigate to="/login" state={{ from: history.location }} />
    }
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState();
    const [modalFilterShow, setFilterModalShow] = React.useState(false);
    const [modalExportShow, setModalExportShow] = React.useState(false);
    const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
    const [modalDeleteData, setModalDeleteData] = React.useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(25);
    const [totalAmount, setTotalAmount] = useState();
    const [reimbursement, setReimbursement] = useState(true);
    const [selectedCount, setSelectedCount] = useState(0);
    let dashboard = useSelector(x => x.dashboard);
    let dashboardList = [];
    dashboardList = dashboard.dashboard.getExpenseReimbursement;
    const dashboardArgs = dashboard.dashboardArgs;
    const apipagination = dashboard.dashboard.pagination;
    let Filterobj = {
        expense_trans_user_id: authUser.user.user_id,
        // expense_trans_uploaded_by: authUser.user.user_id,
        expense_trans_uploaded_by: '',
        expense_trans_from_dt: null,
        expense_trans_to_dt: null,
        expense_trans_uploaded_from_dt: null,
        expense_trans_uploaded_to_dt: null,
        expense_trans_vendor_ids: null,
        expense_trans_amt: '',
        expense_trans_memo: '',
        expense_trans_ticket_number: '',
        expense_trans_bill_to: '',
        expense_trans_bill_to_client_id: '',
        expense_trans_bill_to_project_id: '',
        expense_trans_purchase_type_id: '',
        expense_trans_bill_to_task_id: '',
        expense_trans_bill_to_sub_task_id: '',
        sortColumn: 'expense_trans_dt',
        sortOrder: 'DESC',
        pageNumber: currentPage,
        pageSize: recordsPerPage,
        exportExcel: '',
        isCharge: true
    }
    let filterObjectSearchValues = {};
    useEffect(() => {
        const savedFilterObj = localStorage.getItem('reimbersementFilterObjValues');
        if (savedFilterObj) {
          let localStorageFilerObj = JSON.parse(savedFilterObj);
          localStorageFilerObj.pageNumber = 1;
          localStorageFilerObj.pageSize = 25;
          setCurrentPage(1); 
          setRecordsPerPage(25);
          dispatch(reimbursementDashboardActions.getAll(localStorageFilerObj));
          localStorage.setItem('reimbersementFilterObjValues', JSON.stringify(localStorageFilerObj));
        } else {
          dispatch(reimbursementDashboardActions.getAll(Filterobj));
          localStorage.setItem('reimbersementFilterObjValues', JSON.stringify(Filterobj));
        }
      }, []);

    useEffect(() => {
        var sum = 0;
        for (var i = 0; i < dashboardList?.length; i++) {
            let value = dashboardList[i].expense_trans_amt;
            if (value !== null && value !== "") {
                sum += parseFloat(value.replace(/,/g, ""));
            }
        }

        setTotalAmount(sum.toString());
         // Filter count Display
    const storedFilters = localStorage.getItem('reimbersementFilterObjValues');
    const localStorageFilerObj = JSON.parse(storedFilters);
    const { pageNumber, pageSize, sortColumn, sortOrder, expense_trans_memo, ...localStorageFilterdObj } = localStorageFilerObj;
    const countPropertiesWithValue = (obj) => {
        let count = 0;
        let dateFieldCounted = false;
        let uploadDateFieldCounted = false;
      
        for (const [key, value] of Object.entries(obj)) {
          if ((key === 'expense_trans_from_dt' || key === 'expense_trans_to_dt')) {
            if (!dateFieldCounted && (obj['expense_trans_from_dt'] || obj['expense_trans_to_dt'])) {
              count += 1;
              dateFieldCounted = true;
            }
          } else if ((key === 'expense_trans_uploaded_from_dt' || key === 'expense_trans_uploaded_to_dt')) {
            if (!uploadDateFieldCounted && (obj['expense_trans_uploaded_from_dt'] || obj['expense_trans_uploaded_to_dt'])) {
              count += 1;
              uploadDateFieldCounted = true;
            } 
          }  else if (value !== null && value !== "") {
            count += 1;
          }
        }
        return count;
      };

    const count = countPropertiesWithValue(localStorageFilterdObj);
   
    setSelectedCount(count);

    }, [dashboardList]);
    let formattedValue1 = 0;
    if (totalAmount) {
        formattedValue1 = formatValue({
            value: totalAmount,
            groupSeparator: ',',
            decimalSeparator: '.',
            decimalScale: 2,
        });

    }
    useEffect(() => {
        if (localStorage.getItem('reimbersementFilterObjValues') !== null) {
            let filterObjectSearchValues = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'));
            filterObjectSearchValues.pageNumber = currentPage;
            filterObjectSearchValues.pageSize = recordsPerPage;

            dispatch(reimbursementDashboardActions.getAll(filterObjectSearchValues));
            localStorage.setItem('reimbersementFilterObjValues', JSON.stringify(filterObjectSearchValues));
        } else {
            Filterobj.pageNumber = currentPage;
            Filterobj.pageSize = recordsPerPage;

            dispatch(reimbursementDashboardActions.getAll(Filterobj));
            localStorage.setItem('reimbersementFilterObjValues', JSON.stringify(Filterobj));
        }
    }, [currentPage, recordsPerPage]);


    const columns = [

        { dataField: 'expense_trans_user_id', text: 'EMPLOYEE NAME', sort: true, order: 'default' },
        { dataField: 'expense_trans_dt', text: 'DATE', sort: true, order: 'default' },
        { dataField: 'expense_trans_vendor_name', text: 'VENDOR', sort: true, order: 'default' },
        { dataField: 'expense_trans_amt', text: 'AMOUNT', sort: true, order: 'default' },
        { dataField: 'expense_trans_memo', text: 'DESCRIPTION', sort: true, order: 'default' },
        { dataField: 'expense_trans_ticket_number', text: 'TICKET NUMBER', sort: true, order: 'default' },
        { dataField: 'expense_trans_bill_to', text: 'BILLED TO', sort: true, order: 'default'},
        { dataField: 'expense_trans_uploaded_by', text: 'UPLOADED BY', sort: true, order: 'default' },
        { dataField: 'expense_trans_uploaded_dt', text: 'UPLOADED DATE', sort: true, order: 'default'},
    ];


    function sortClicked(columnObj) {
        if (columnObj.dataField == dashboardArgs?.sortColumn) {
            const orderAPI = dashboardArgs.sortOrder === 'DESC'
                ? 'ASC'
                : 'DESC';
            if (localStorage.getItem('reimbersementFilterObjValues')) {
                filterObjectSearchValues = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'));
                filterObjectSearchValues.sortColumn = columnObj.dataField;
                filterObjectSearchValues.sortOrder = orderAPI;
                localStorage.setItem('reimbersementFilterObjValues', JSON.stringify(filterObjectSearchValues));
            }

        } else {

            if (columnObj.sort === false) {
                return;
            }
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].dataField !== columnObj.dataField) {
                    columns[i].order = 'default';
                }
                else {
                    columns[i].order =
                        columns[i].order === 'default' ||
                            columns[i].order === 'DESC'
                            ? 'ASC'
                            : 'DESC';

                    if (localStorage.getItem('reimbersementFilterObjValues')) {
                        filterObjectSearchValues = JSON.parse(localStorage.getItem('reimbersementFilterObjValues'));
                        filterObjectSearchValues.sortColumn = columnObj.dataField;
                        filterObjectSearchValues.sortOrder = columns[i].order;
                        localStorage.setItem('reimbersementFilterObjValues', JSON.stringify(filterObjectSearchValues));

                    }
                }

            }
        }

        const copyFilterobj = Object.assign({}, filterObjectSearchValues);

        dispatch(reimbursementDashboardActions.getAll(copyFilterobj));
        copyFilterobj.pageNumber = '';
        copyFilterobj.pageSize = '';

    }
    var nPages;
    if (dashboardList?.length > 0) {
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = dashboardList.slice(indexOfFirstRecord, indexOfLastRecord);
        nPages = Math.ceil(apipagination.countRows / recordsPerPage)

    }

    const [fullscreen, setFullscreen] = React.useState(true);
    function openEditOverlay(rowData) {
        setModalShow(true);
        setModalData(rowData)
        setFullscreen(true);
    }
    function openDeleteOverlay(rowData) {
        setModalDeleteData(rowData.expense_trans_id)
        setModalDeleteShow(true);
    }


    return (
        <>
            <FormModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                modaldata={modalData}
                fullscreen={fullscreen}
                reimbursement={reimbursement}
            />
            <FilterModal
                show={modalFilterShow}
                onHide={() => setFilterModalShow(false)}
                filterobj={Filterobj}
                setcurrentpage={setCurrentPage}
                setrecordsperpage={setRecordsPerPage}
                reimbursement={reimbursement}
            />

            <ReimbursementExportModal
                show={modalExportShow}
                onHide={() => setModalExportShow(false)}
            />
            <ReimbursementDeleteModal
                show={modalDeleteShow}
                modaldata={modalDeleteData}
                reimbursement={reimbursement}
                onHide={() => setModalDeleteShow(false)}
            />
            <div className="reimbursement-dashboard dashboards">
                {dashboard.loading &&
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}

                <main>
                    <div className="container">
                        <div className='filter-container'>
                            <div className='dash-icon'>
                                <div></div>
                                <h6 className='dash-title dash-title-dashboard'>EXPENSE REIMBURSEMENT DASHBOARD</h6>
                                <div className='dash-trigger-icons'>
                                    <OverlayTrigger
                                        key='tooltip-exports'
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-export'>
                                                Export Files
                                            </Tooltip>
                                        }
                                    >
                                        <a onClick={() => setModalExportShow(true)}><img src={'/assets/images/export.jpg'} /></a>

                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        key='upload'
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-upload'>
                                                Expense Reimbursement
                                            </Tooltip>
                                        }
                                    >
                                        <a href="/ExpenseReimbursementUploadReceipt"><img src={'/assets/images/upload.png'} /></a>

                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        key='filter'
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-filter'>
                                                Filter
                                            </Tooltip>
                                        }
                                    >
                                        <a onClick={() => setFilterModalShow(true)}> <img src={'/assets/icons/filter.svg'} />{selectedCount > 0 && <span className='filter-count'>{ `${selectedCount}`}</span>}</a>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="login-form ">
                                <div className="table-container table-responsive">
                                    <table className="credit-table-container table-layout">
                                        <thead>
                                            <tr>
                                                {columns.map((columnObj, index) => {
                                                    return (
                                                        <th key={index} onClick={() => sortClicked(columnObj)}>
                                                            {dashboardArgs.sortColumn == columnObj.dataField ?
                                                                (<span >{columnObj.text}
                                                                    <span className={dashboardArgs.sortOrder == 'DESC' ? 'sort-desc-icon' : dashboardArgs.sortOrder == 'ASC' ? 'sort-asc-icon' : 'sort-default-icon'}></span>
                                                                </span>
                                                                ) : (
                                                                    <span >{columnObj.text}
                                                                        <span className='sort-default-icon'></span>
                                                                    </span>)}
                                                        </th>
                                                    )
                                                })}
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="scroll-bar">
                                        <table className="credit-list-table table-layout">
                                            <tbody>
                                                {dashboardList?.map((listValue, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{listValue.expense_trans_user_id}</td>

                                                            <td>{listValue.expense_trans_dt ? moment(listValue.expense_trans_dt).format('MM/DD/YYYY') : ''}</td>
                                                            <td>{listValue.expense_trans_vendor_name}</td>
                                                            <td className={parseFloat(listValue.expense_trans_amt, 10) > 0 ? 'positive' : 'negative'}>{parseFloat(listValue.expense_trans_amt, 10) > 0 ? '$' + listValue.expense_trans_amt : (listValue.expense_trans_amt == '' || listValue.expense_trans_amt == null) ? '' : '(' + '$' + listValue.expense_trans_amt.slice(1) + ')'}</td>
                                                            <td>{listValue.expense_trans_memo}</td>
                                                            <td>{listValue.expense_trans_ticket_number}</td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    key='bottom'
                                                                    placement='left'

                                                                    overlay={
                                                                        <Tooltip id='tooltip-bottom'>
                                                                            {listValue.expense_trans_bill_to_details}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>{listValue.expense_trans_bill_to_details}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>{listValue.expense_trans_uploaded_by}</td>
                                                            <td>{listValue.expense_trans_uploaded_dt ? moment(listValue.expense_trans_uploaded_dt).format('MM/DD/YYYY') : ''}</td>
                                                            <td>  <OverlayTrigger
                                                                        key='bottom-view'
                                                                        placement='bottom'

                                                                        overlay={
                                                                            <Tooltip id='tooltip-bottom'>
                                                                                View Receipt
                                                                            </Tooltip>
                                                                        }>
                                                                            <a href={listValue.file_path + '?' + new Date().getTime()} target="_blank"><img src={'/assets/icons/view-small.svg?'} /></a>
                                                                    </OverlayTrigger></td>
                                                            {listValue.expense_trans_status == 'S' ?
                                                                <td>

                                                                    <OverlayTrigger
                                                                        key='bottom-edit'
                                                                        placement='bottom'

                                                                        overlay={
                                                                            <Tooltip id='tooltip-bottom'>
                                                                                Edit
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <img src={'/assets/icons/edit-small.svg'} onClick={() => openEditOverlay(listValue)}></img>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                        key='bottom-delete'
                                                                        placement='bottom'

                                                                        overlay={
                                                                            <Tooltip id='tooltip-bottom'>
                                                                                Delete
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <img src={'/assets/icons/delete.svg'} onClick={() => openDeleteOverlay(listValue)}></img>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                : <td>{listValue.expense_trans_status == 'P' ? (<span className="badge badge-success">PAID</span>) : listValue.expense_trans_status == 'V' ? (<span className="badge badge-purple">VERIFIED</span>) : (<span></span>)}</td>}                                                           
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>

                                        </table>
                                        {dashboardList?.length <= 0 &&
                                            <div className='no-records'>No Data found</div>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </main>
            </div>
            {dashboardList?.length > 0 && (
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={apipagination.countRows}
                    pageSize={recordsPerPage}
                    pageRange={apipagination.pageRange}
                    setCurrentPage={setCurrentPage}
                    setRecordsPerPage={setRecordsPerPage}
                    totalAmount={formattedValue1}
                    onPageChange={page => setCurrentPage(page)}
                />
            )}
        </>
    );
}